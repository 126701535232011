import React, { useState, useEffect } from 'react';
import axios from 'axios';

import PsContext from './index';
import { getLs, setLs } from '../utils';
import { listSettingsFields } from '../models/fieldSettings';

const PsContextProvider = (props) => {

	const checkUserLogged = () => {
		return getLs('coa_logged') || 'no';
	};

	const getAdminUser = () => {
		return getLs('coa_user_data') ? JSON.parse(getLs('coa_user_data')) : [];
	};

	const getAdminCompany = () => {
		return getLs('coa_company_data') ? JSON.parse(getLs('coa_company_data')) : [];
	};

	const getAdminApi = () => {
		return getLs('coa_api') || '';
	};

	const getSettings = () => {
		return getLs('adm_settings') ? JSON.parse(getLs('adm_settings')) : [];
	};

	const getAcyear = () => {
		return getLs('adm_acyear_cur') ? JSON.parse(getLs('adm_acyear_cur')) : [];
	};

	const [state, setState] = useState([]);

	const [logged, setLogged] = useState(checkUserLogged());
	const [user, setUser] = useState(getAdminUser());
	const [api, setApi] = useState(getAdminApi());

	const [backgroundProcess, setBackgroundProcess] = useState(false);

	const [settings, setSettings] = useState(getSettings());
	const [acyear, setSAcyear] = useState(getAcyear());

	const [selectedAdmissionYear, setadmissionYear] = useState('2022');

	const saveLogin = (user, api) => {
		setLs('coa_user_data', JSON.stringify(user));
		setUser(user);
		setLs('coa_api', api);
		setApi(api);
		//setLs('mat_logged', 'yes');
		//setLogged('yes');
	};

	const logout = () => {
		setLs('coa_user_data', false);
		setUser([]);
		setSAcyear([]);
		setLs('coa_api', '');
		setLs('adm_acyear_cur', false);
		setApi(null);
		axios.defaults.headers.common['Api-Token'] = '';
		setLs('coa_logged', 'no');
		setLogged('no');
	};

	const updateUser = (user) => {
		setLs('coa_user_data', JSON.stringify(user));
		setUser(user);
	};

	const updateLogged = () => {
		setLs('coa_logged', 'yes');
		setLogged('yes');
	};

	const loadSettings = () => {
		listSettingsFields().then(res => {
			setLs('adm_settings', JSON.stringify(res));
			setSettings(res);
		});
	};

	const settingValue = (key) => {
		let m = settings.find(item => item.field_name == key);
		return (m && m.field_name) ? m.field_value : false;
	};

	const updateAcyear=(v)=>{
		setSAcyear(v);
		setLs('adm_acyear_cur', JSON.stringify(v));
	}
	return (
		<PsContext.Provider value={{
			state: state,
			logged: logged,
			user: user,
			acyear: acyear,
			api: api,
			saveLogin: saveLogin,
			updateUser: updateUser,
			updateLogged: updateLogged,
			setBackgroundProcess: setBackgroundProcess,
			backgroundProcess: backgroundProcess,
			logout: logout,
			loadSettings: loadSettings,
			settingValue: settingValue,
			selectedAdmissionYear: selectedAdmissionYear,
			updateAcyear: updateAcyear,
		}}
		>
			{props.children}
		</PsContext.Provider>
	);
};

export default PsContextProvider;